import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {BrowserRouter, useHistory} from "react-router-dom";
import './App.css';
import { ObjectToString, StringToObject } from "./common/constants";
import { Modal } from './components/modal/Modal';
import Routes from "./routes/routes";
import {changeLanguageAction, logoutUserAction, setUserAction, showModalAction} from "./store/actions";
import GlobalStyles from "./styles/glopbal-styles";
import { ReduxState } from "./types/types";
import {isMobile} from "react-device-detect";
import {DownloadAppModal} from "./components/modal/download-app-modal/DownloadAppModal";
import {Api} from "./api/vuSpaceApi";


function App() {
  const [dir,lang] = useSelector(({preferences}:any) => [preferences.lang.dir,preferences.lang.name]);
  const user = useSelector(({user}:ReduxState) => user)
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.dir = dir;
  }, [dir]);
  useEffect(()=>{
      if (localStorage.getItem("USER_LANG")){
          // @ts-ignore
          dispatch(changeLanguageAction(localStorage.getItem("USER_LANG")))
      }
  },[lang])
  useEffect(() => {
    if (!localStorage.getItem("USER_WEB_LOCATION")){
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(position=> {
            localStorage.setItem("USER_WEB_LOCATION",JSON.stringify([position.coords.longitude,position.coords.latitude]))
        })
      }
    }
    if (localStorage.getItem("USER_WEB_INFO")) {
      dispatch(setUserAction(StringToObject(localStorage.getItem("USER_WEB_INFO"))))
    }
  }, [])
  useEffect(() => {
    localStorage.setItem("USER_WEB_INFO",ObjectToString(user._id,user.token,user.name,user.email,user.phone,user.countryCode,user.favoriteDevelopersIds,user.favoriteProjectsIds,user.favoritePropertiesIds))
  }, [user])

  //#region Download APP modal popup
  useEffect(()=>{
    if(isMobile) {
        if (localStorage.getItem('APP_DOWNLOAD_REMINDER_TIME')) {
            let date = Date.now()
            if (date.toString() > localStorage.getItem('APP_DOWNLOAD_REMINDER_TIME')!){
                setTimeout(()=>{
                    dispatch(showModalAction(<DownloadAppModal/>))
                },15000)
            }
        } else {
            setTimeout(()=>{
                dispatch(showModalAction(<DownloadAppModal/>))
            },15000)
        }
    }
  },[])
  //#endregion

  return (
      <div className="vs-app">
        <GlobalStyles/>
        <BrowserRouter>
          <Routes/>
        </BrowserRouter>
        <Modal />
      </div>
  );
}

export default App;
