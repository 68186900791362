import { showGalleryModalActionType, showVideoModalActionType, showFullViewModalActionType } from './../../types/types';
import { showModalActionType, hideModalActionType, showCreateModalActionType } from '../../types/types';

//Todo get initial state from local storage or from the server

const defaultUiState = {
    modal:{
        isActive:false,
        content:null,
        variant:false,
    }
}

export const uiReducer = (uiState = defaultUiState, action:showModalActionType|hideModalActionType|showCreateModalActionType|showGalleryModalActionType|showVideoModalActionType|showFullViewModalActionType) => {
    if (action.type === 'SHOW_MODAL') {
        return {
            ...uiState,
            modal:{
                isActive:true,
                content:action.payload,
                variant: 'normal',

            }
        }
    }
    if (action.type === 'SHOW_CREATE_MODAL') {
        return {
            ...uiState,
            modal:{
                isActive:true,
                content:action.payload,
                variant: 'create',

            }
        }
    }
    if (action.type === 'SHOW_GALLERY_MODAL') {
        return {
            ...uiState,
            modal:{
                isActive:true,
                content:action.payload,
                variant: 'gallery',

            }
        }
    }
    if (action.type === 'SHOW_VIDEO_MODAL') {
        return {
            ...uiState,
            modal:{
                isActive:true,
                content:action.payload,
                variant: 'video',

            }
        }
    }
    if (action.type === 'SHOW_FULL_VIEW_MODAL') {
        return {
            ...uiState,
            modal:{
                isActive:true,
                content:action.payload,
                variant: 'full-view',

            }
        }
    }
    if (action.type === 'HIDE_MODAL') {
        return {
            ...uiState,
            modal:{
                isActive:false,
                content:null,
                variant:false,
            }
        }
    }

return uiState;
}