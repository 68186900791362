import {BREAKPOINTS} from "../common/constants";
import {css} from "styled-components";

export const MEDIA_SILVER = (...args) => css`
  @media (min-width: ${BREAKPOINTS.SILVER + "px"}) {
    ${css(...args)}
  }
`
export const MEDIA_GOLD = (...args) => css`
  @media (min-width: ${BREAKPOINTS.GOLD + "px"}) {
    ${css(...args)}
  }
`
export const MEDIA_PLATINUM = (...args) => css`
  @media (min-width: ${BREAKPOINTS.PLATINUM + "px"}) {
    ${css(...args)}
  }
`
export const MEDIA_IPAD_PRO = (...args) => css`
  @media (min-device-width: ${BREAKPOINTS.PLATINUM + "px"}) 
  and (max-device-width: ${BREAKPOINTS.PLATINUM + "px"})
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {
    ${css(...args)}
  }
`
export const MEDIA_DIAMOND = (...args) => css`
  @media (min-width: ${BREAKPOINTS.DIAMOND + "px"}) {
    ${css(...args)}
  }
`