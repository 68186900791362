import {
    showModalActionType,
    hideModalActionType,
    showCreateModalActionType,
    showGalleryModalActionType,
    showVideoModalActionType,
    changeLocationPermissionActionType,
    setSearch,
    SearchParams,
    resetSearch,
    showFullViewModalActionType,
    UserReducerState,
    setUserActionType,
    logoutUserActionType,
    CompareReducerState, setCompareProperty, resetCompare
} from './../types/types';
import {
    changeLanguageActionType,
    changeThemeActionType,
    language,
    theme
} from "../types/types";

export const ACTIONS = Object.freeze({
    //normal actions
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    CHANGE_THEME: "CHANGE_THEME",
    SHOW_MODAL:'SHOW_MODAL',
    HIDE_MODAL:'HIDE_MODAL',
})
export const changeLanguageAction = (payload: language): changeLanguageActionType => {
    return {
        type: "CHANGE_LANGUAGE",
        payload
    }
}
export const changeLocationPermissionAction = (payload: boolean): changeLocationPermissionActionType => {
    return {
        type: "CHANGE_LOCATION_PERMISSION",
        payload
    }
}
export const changeThemeAction = (payload: theme): changeThemeActionType => {
    return {
        type: "CHANGE_THEME",
        payload
    }
}
export const showModalAction = (payload: JSX.Element|null): showModalActionType => {
    return {
        type: "SHOW_MODAL",
        payload
    }
}
export const showCreateModalAction = (payload: JSX.Element|null): showCreateModalActionType => {
    return {
        type: "SHOW_CREATE_MODAL",
        payload
    }
}
export const showGalleryModalAction = (payload: JSX.Element|null): showGalleryModalActionType => {
    return {
        type: "SHOW_GALLERY_MODAL",
        payload
    }
}
export const showVideoModalAction = (payload: JSX.Element|null): showVideoModalActionType => {
    return {
        type: "SHOW_VIDEO_MODAL",
        payload
    }
}
export const showFullViewModalAction = (payload: JSX.Element|null): showFullViewModalActionType => {
    return {
        type: "SHOW_FULL_VIEW_MODAL",
        payload
    }
}
export const hideModalAction = (): hideModalActionType => {
    return {
        type: "HIDE_MODAL",
    }
}
export const setSearchAction = (payload:SearchParams): setSearch => {
    return{
        type: 'SET_SEARCH',
        payload
    }
}
export const resetSearchAction = (): resetSearch => {
    return{
        type: 'RESET_SEARCH'
    }
}
//#region User Actions
export const setUserAction = (payload:UserReducerState):setUserActionType => {
    return {
        type: 'SET_USER',
        payload
    }
}
export const logoutUserAction = ():logoutUserActionType => {
    return {
        type: 'LOGOUT_USER'
    }
}
//#endregion
//#region User Actions
export const setComparePropertyAction = (payload:CompareReducerState):setCompareProperty => {
    return {
        type: 'SET_COMPARE',
        payload
    }
}
export const resetCompareAction = ():resetCompare => {
    return {
        type: 'RESET_COMPARE'
    }
}
//#endregion
