import { userReducer } from './user-reducer';
import { searchReducer } from './search-reducer';
import {combineReducers} from "redux";
import {preferencesReducer} from "./preferences-reducer";
import {uiReducer} from "./ui-reducer";
import {compareReducer} from "./compare-reducer";

export const reducers = combineReducers({
	preferences: preferencesReducer,
	ui: uiReducer,
	search: searchReducer,
	user: userReducer,
	compare:compareReducer,
})