import { DEFAULT_USER_TOKEN } from '../../common/constants';
import { setUserActionType, logoutUserActionType } from './../../types/types';

const defaultUserState = {
    _id:'',
    name:'',
    email:'',
    phone:'',
    countryCode:'',
    token:DEFAULT_USER_TOKEN,
    favoriteDevelopersIds:[],
    favoriteProjectsIds:[],
    favoritePropertiesIds:[],
}

export const userReducer = (userState = defaultUserState, action:setUserActionType|logoutUserActionType) => {
    if (action.type === 'SET_USER') {
        return {
            _id: action.payload._id,
            name: action.payload.name,
            email: action.payload.email,
            phone: action.payload.phone,
            countryCode: action.payload.countryCode,
            token: action.payload.token,
            favoriteDevelopersIds:action.payload.favoriteDevelopersIds,
            favoriteProjectsIds:action.payload.favoriteProjectsIds,
            favoritePropertiesIds:action.payload.favoritePropertiesIds
        }
    }
    if (action.type === 'LOGOUT_USER') {
        return {
            _id: "",
            name: "",
            email: "",
            phone: "",
            countryCode: "",
            token: DEFAULT_USER_TOKEN,
        }
    }
    return userState
}
