import {createStore,applyMiddleware, compose} from 'redux';
import {reducers} from "./reducers/reducers";

//middlewares
const middlewares = [];
//enhancers
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
//store
const store = composeEnhancers
	? createStore(reducers, compose(applyMiddleware(...middlewares),composeEnhancers))
	: createStore(reducers, compose(applyMiddleware(...middlewares)))
;

export default store;

