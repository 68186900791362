const DownloadIcon = props => {
	
	return (
		<svg
			width={139}
			height={113}
			viewBox="0 0 139 113"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M99.85 37.17h29.816l6.667 6.666v59.461l-6.667 6.667h-120L3 103.297v-59.46l6.666-6.668h29.868"
				className='pathOne'
				strokeWidth={5.333}
				strokeMiterlimit={10}
			/>
			<path
				d="M69.667.653v72.915M39.497 48.11l25.456 25.457h9.428L99.837 48.11"
				className='pathTwo'
				strokeWidth={5.333}
				strokeMiterlimit={10}
			/>
		</svg>
	)
};

export default DownloadIcon;
