import screenfull from "screenfull";
import {IMAGE_FORMATS, MONTHS, VIDEO_FORMATS} from "./constants";

export const checkUrlMatch = (currentPath: string, targetPath: any) => {
  targetPath = targetPath[0] === "/" ? targetPath : "/" + targetPath;
  return currentPath === targetPath;
};

//@ts-ignore
export const pipe = (...fns) => fns.reduce((acc,fn)=> (...args)=> fn(acc(...args)))

export const formatDate = (date:Date)=> `${MONTHS[date.getMonth()].toUpperCase()} ${date.getDate()}, ${date.getFullYear()}`

export const formatDateLowerCase = (date:Date)=> `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`

export const dateToInputString = (date:Date)=> `${date.getFullYear()}-${date.getMonth()<10 ?`0${date.getMonth()+1}`:date.getMonth()+1}-${date.getDate()<10?`0${date.getDate()}`:date.getDate()}`

const toDate = (date:string)=> new Date(date)

export const reFormatDateString = pipe(toDate,formatDate);

export const reFormatDateStringLowerCase = pipe(toDate,formatDateLowerCase);


const urlToFileFormat = (url:string) => {
  const regex = /[^.?]+(?=\?)/
  const match = url.match(regex);
  return match ? match[0] : '';
}

export const ConvertToArabicNumbers = (num) => {
  const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
  return new String(num).replace(/[0123456789]/g, (d)=>{return arabicNumbers[d]});
}

export const convertFinishingStatusToArabic = (status:string) => {
  if (status === 'Finished') return "تشطيب كامل";
  if (status === 'Semi-Finished') return "نصف تشطيب";
  if (status === 'Not-Finished') return "بدون تشطيب";
  if (status === 'Fully finished with ac') return "تشطيب كامل بالتكييفات";
  if (status === 'Core and shell') return "بدون تشطيب";
}

export const convertPaymentTypeToArabic = (type:string) => {
  if (type.toLocaleLowerCase() === 'monthly') return "شهري";
  if (type.toLocaleLowerCase() === 'quarterly') return "ربع سنوي";
  if (type.toLocaleLowerCase() === 'semi-annually') return "نصف سنوى";
  if (type.toLocaleLowerCase() === 'yearly') return "سنوي";
  if (type.toLocaleLowerCase() === 'up-to') return "يصل إلى";
}

export const isVideoUrl = (url:string) => VIDEO_FORMATS.includes(urlToFileFormat(url))

export const isImageUrl = (url:string) => IMAGE_FORMATS.includes(urlToFileFormat(url))

//@ts-ignore
export const isValidDate = (d) => d instanceof Date && !isNaN(d)


export const removeVarFromCSS = (s:string) => {
  const regex = /--[^\)]+/g
  const match = s.match(regex);
  return match ? match[0] : '';
}

export const toggleFullScreen = (ref) =>{
    if (screenfull.isEnabled) {
        //@ts-ignore
        screenfull.toggle(ref.current)
        // screenfull.on('change', () => {
        //   console.log('Am I fullscreen?',
        //     //@ts-ignore
        //     screenfull.isFullscreen ? 'Yes' : 'No');
        // });
        // screenfull.off('change', ()=>console.log('off'));
    }
}

export const isIOS = () => {
  return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
export const isIPhone = () => {
  return [
        'iPhone Simulator',
        'iPhone',
      ].includes(navigator.platform)
}
export const isIPad = () => {
  return [
        'iPad Simulator',
        'iPad',
      ].includes(navigator.platform)
}

export const isSafari = navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i) &&
    !navigator.userAgent.match(/Opera|OPT\//);

export const objectsEqual = (o1, o2) =>
  Object.keys(o1).length === Object.keys(o2).length
  && Object.keys(o1).every(p => o1[p] === o2[p]);

export const arraysEqual = (a1, a2) =>
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));