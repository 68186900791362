import {ComponentPropsWithRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled, {css} from "styled-components";
import {COLORS} from "../../common/constants";
import {hideModalAction} from "../../store/actions";
import {MEDIA_DIAMOND, MEDIA_GOLD, MEDIA_PLATINUM, MEDIA_SILVER} from "../../styles/responsive";
import {ReduxState} from "../../types/types";

const CSSCreate = css`
    /* & .vs-modal-close{
   position:absolute;
   top:25px;
   right:10px;
   left:auto;
   width:fit-content;
   color:${COLORS.GRAY.d2};
   background-color: transparent;
   border:0px;
   font-size:24px;
   cursor:pointer
 } */

  & .modal-wrapper {
    position: absolute;
    user-select: none;
    background-color: ${COLORS.BACKGROUND.d2};
    border: 1px solid ${COLORS.GRAY.d2};
    border-radius: 5px;
    top: 50%;
    left: 50%;
    padding: 0;
    transform: translate(-50%, -50%);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: ${COLORS.BACKGROUND.d2};
  }
`;

const CSSShowModal = css`
  display: block;
`;

const CSSGalleryModal = css`
& .modal-wrapper {
    position: absolute;
    user-select: none;
    background-color: ${COLORS.BACKGROUND.d2};
    border: 1px solid ${COLORS.GRAY.d2};
    border-radius: 5px;
    top: 50%;
    left: 50%;
    padding: 0;
    width: calc(100vw - 40px);
    transform: translate(-50%, -50%);
    & .vs-swiper-gallery{
      height: 400px;
      & img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    & .vs-swiper-thumbs{
      height: 20%;
      max-height: 150px;
      box-sizing: border-box;
      padding: 10px 0;
      & .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
        & img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      & .swiper-slide-thumb-active {
        opacity: 1;
      } 
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: ${COLORS.BACKGROUND.d2};
  }
`

const CSSVideoModal = css`
  & .modal-wrapper {
    position: absolute;
    border: 1px solid ${COLORS.BACKGROUND.d1};
    border-radius: 5px;
    overflow: hidden;
    top: 50%;
    left: 50%;
    padding: 0;
    width: calc(100vw - 40px);
  	max-width: 1200px;
    transform: translate(-50%, -50%);
  }
  &:before {
    opacity: 0.9;
  }
`

const CSSFullViewModal = css`
& .vs-modal-close{
  display: none;
}
& .modal-wrapper{
  user-select: auto;
  overflow: auto;
  background: ${COLORS.BACKGROUND.gradient};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  z-index:111;
  transform: translate(0, 0);
  &:before{
    display: none;
  }
}
&:before {
  display: none;
}
`

const Bronze = css`
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index:100;

  & .vs-modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    left: auto;
    width: fit-content;
    color: ${COLORS.GRAY.d2};
    background-color: transparent;
    border: 0px;
    font-size: 24px;
    cursor: pointer
  }

  & .modal-wrapper {
    position: absolute;
    user-select: none;
    background-color: ${COLORS.BACKGROUND.d1};
    border-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  	& .vs-error-modal{
		background-color: ${COLORS.DANGER};
		border-radius: 5px;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		width: 240px;
		& svg{
			width: 24px;
			height: 24px;
			margin-bottom: 20px;
			stroke: ${COLORS.GRAY.d1};
			& .pathOne{
				stroke-width: 10;
			}
			& .pathTwo {
				stroke-width: 10;
			}
			
		}
	}
    &:before{
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 5px;
      border: 2px solid ${COLORS.GRAY.d1};
      opacity: 0.1;
      top: 0;
      left: 0;
      z-index:-1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: ${COLORS.BACKGROUND.d2};
  }
	
  & .vs-image-modal-wrapper{
	  width: 50vw;
	  & img{
		  width: 100%;
		  height: 100%;
		  object-fit: cover;
	  }
  }
  [dir = 'ltr'] & {
  }

  [dir = 'rtl'] & {
  }

  ${({isActive}: sModalProps) => isActive && CSSShowModal}
  ${({variant}: sModalProps) => variant === 'create' && CSSCreate}
  ${({variant}: sModalProps) => variant === 'gallery' && CSSGalleryModal}
  ${({variant}: sModalProps) => variant === 'video' && CSSVideoModal}
  ${({variant}: sModalProps) => variant === 'full-view' && CSSFullViewModal}
`
const Silver = css`

`

const Gold = css`

`

const Platinum = css`

`

const Diamond = css`

`
type modalProps = ComponentPropsWithRef<"div">;

type sModalProps = {
	isActive?: boolean
	variant: string
} & ComponentPropsWithRef<'div'>;

const SModal = styled.div<sModalProps>`
  ${Bronze}
  ${MEDIA_SILVER`${Silver}`}
  ${MEDIA_GOLD`${Gold}`}
  ${MEDIA_PLATINUM`${Platinum}`}
  ${MEDIA_DIAMOND`${Diamond}`}
`
export const Modal = ({children, ...props}: modalProps) => {
	const {isActive, content, variant} = useSelector(({ui}: ReduxState) => ui.modal);
	const dispatch = useDispatch()
	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.target === e.currentTarget && dispatch(hideModalAction());
	return (
		<SModal isActive={isActive} variant={variant} onClick={handleClick} {...props}>
			{/* <div className="vs-modal-close" onClick={() => dispatch(hideModalAction())}>✕</div> */}
			<div className="modal-wrapper">
				{content}
			</div>
		</SModal>
	);
}