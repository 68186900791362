import styled, {css} from "styled-components";
import {MEDIA_DIAMOND, MEDIA_GOLD, MEDIA_PLATINUM, MEDIA_SILVER} from "../../../styles/responsive";
import {ComponentPropsWithRef, useEffect} from "react";
import SignOutIcon from "../../icons/SignOutIcon";
import {ButtonMain} from "../../form-components/buttons/ButtonMain";
import {hideModalAction} from "../../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../../../types/types";
import DownloadIcon from "../../icons/DownloadIcon";
import {COLORS} from "../../../common/constants";
import {isAndroid,isIOS} from 'react-device-detect'

const Bronze = css`
  padding: 25px 10px;
  width: calc(100vw - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  & .vs-log-out-icon{
    margin-bottom: 30px;
    & svg{
      width: 50px;
      height: 50px;
      & .pathOne{
        stroke: ${COLORS.PRIMARY.d5};
        stroke-width: 10;
      }
      & .pathTwo{
        stroke: ${COLORS.GRAY.d1};
        stroke-width: 10;
      }
    }
  }
  & .vs-logout-title{
    font-size: 18px;
    font-weight: bold;
    color: ${COLORS.GRAY.d1};
    margin-bottom: 10px;
  }
  & .vs-log-out-message{
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 30px;
  }
  & .vs-action-buttons{
    display: flex;
    justify-content: space-around;
    width: 100%;
    & button{
      font-size: 14px;
      flex-basis: 40%;
      max-width: 200px;
    }
  }
  [dir = 'ltr'] & {

  }

  [dir = 'rtl'] & {

  }

`

const Silver = css`
  padding: 30px 20px;
  width: 400px;
  & .vs-log-out-icon{
    & svg{
      width: 60px;
      height: 100px;
    }
  }
  & .vs-logout-title{
    font-size: 22px;
  }
  & .vs-log-out-message{
    font-size: 16px;
  }
  & .vs-action-buttons{
    display: flex;
    & button{
      max-width: 250px;
      font-size: 18px;
    }
  }
`

const Gold = css`
`

const Platinum = css`
  padding: 40px 20px;
  width: 470px;
  & .vs-log-out-icon{
    margin-bottom: 30px;
    & svg{
      width: 60px;
      height: 74px;
    }
  }
  & .vs-log-out-message{
    margin-bottom: 40px;
  }
  & .vs-action-buttons{
    width: 100%;
    & button{
      height: 40px;
      font-size: 18px;
      width: 200px;
    }
  }
`

const Diamond = css`

`
type downloadAppModalProps = {} & ComponentPropsWithRef<"div">;

const SDownloadAppModal = styled.div`
${Bronze}
${MEDIA_SILVER`${Silver}`}
${MEDIA_GOLD`${Gold}`}
${MEDIA_PLATINUM`${Platinum}`}
${MEDIA_DIAMOND`${Diamond}`}
`

export const DownloadAppModal = ({children,...props}:downloadAppModalProps) => {
    const dispatch = useDispatch();
    const dir = useSelector((state:ReduxState) => state.preferences.lang.dir);
    useEffect(()=>{
        let date = Date.now() + 2.592e+8
        localStorage.setItem('APP_DOWNLOAD_REMINDER_TIME',date.toString())
    },[])
    const downloadApp = () => {
        if (isAndroid) {
            window.open("https://play.google.com/store/apps/details?id=com.vu_space.vu_space");
        }else if (isIOS) {
            window.open("https://apps.apple.com/eg/app/vu-space/id1578868637");
        }
        dispatch(hideModalAction())
    }
return (
<SDownloadAppModal>
    <div className="vs-log-out-icon">
        <DownloadIcon/>
    </div>
    <div className="vs-logout-title">{dir==='ltr'?'Download App!':'تحميل التطبيق!'}</div>
    <div className="vs-log-out-message">
        {dir==='ltr'?'Enjoy better experience with our app':"استمتع بتجربة أفضل مع تطبيقنا"}
    </div>
    <div className="vs-action-buttons">
        <ButtonMain variant='hollow-green' onClick={downloadApp}>{dir==='ltr'?'Confirm':"تحميل التطبيق"}</ButtonMain>
        <ButtonMain variant='hollow-gray' onClick={()=>dispatch(hideModalAction())}>{dir==='ltr'?'Cancel':"إلغاء"}</ButtonMain>
    </div>
</SDownloadAppModal>
)
}