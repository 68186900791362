import { setSearch, resetSearch } from './../../types/types';
const defaultSearch = {
    projectNames:[],
	projectFeatures:[],
	projectDevelopers:[],
	propertyNames:[],
	propertyAmenities:[],
	propertyCategories:[],
	propertySubCategories:[],
	propertyMaxPrice:null,
	propertyMinPrice:null,
	propertyMaxSize:null,
	propertyMinSize:null,
	propertyFinishingStatus:null,
	propertyMinRoomsCount:null,
	propertyMaxRoomsCount:null,
	propertyMinBathRoomsCount:null,
	propertyMaxBathRoomsCount:null,
	district:null,
	city:null,
	country:null,
	searchKeywords:null
}

export const searchReducer = (searchState = defaultSearch, action:setSearch|resetSearch) => {
    if (action.type === 'SET_SEARCH') {
        return {...searchState,...action.payload}
    }
    if (action.type === 'RESET_SEARCH') {
        return {...defaultSearch}
    }
    return searchState
}
