import {CompareReducerState, resetCompare, setCompareProperty} from "../../types/types";

const defaultCompareState:CompareReducerState = {
    comparedProperties:[],
}

export const compareReducer = (userState = defaultCompareState, action:setCompareProperty|resetCompare) => {
    if (action.type === 'SET_COMPARE') {
        return {
            comparedProperties: action.payload.comparedProperties,
        }
    }
    if (action.type === 'RESET_COMPARE') {
        return {
            comparedProperties: [],
        }
    }
    return userState
}
