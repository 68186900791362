import {lazy, Suspense, useEffect} from 'react';
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import { LoadingDashBoard } from '../components/loading-component/LoadingDashBoard';
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../types/types";
import {Api} from "../api/vuSpaceApi";
import {logoutUserAction, setUserAction} from "../store/actions";
import {ifError} from "assert";

//test
const MadhoodPage = lazy(() => import('../pages/madhood/MadhoodPage'));
const MarozizPage = lazy(() => import('../pages/maroziz/MarozizPage'));
const YoussefPage = lazy(() => import('../pages/youssef/YoussefPage'));
const ShalabyPage = lazy(() => import('../pages/shalaby/ShalabyPage'));
const ProjectPage = lazy(() => import('../pages/project/ProjectPage'));
const PropertyPage = lazy(() => import('../pages/property/PropertyPage'));
const DeveloperPage = lazy(() => import('../pages/developer/DeveloperPage'));
const ExplorePage = lazy(() => import('../pages/explore/ExplorePage'));
const FilterPage = lazy(() => import('../pages/filter/FilterPage'));
const HomePage = lazy(() => import('../pages/home/HomePage'));
const RegisterPage = lazy(() => import('../pages/register/RegisterPage'));
const LoginPage = lazy(() => import('../pages/login/LoginPage'));
const SavedPage = lazy(() => import('../pages/saved/SavedPage'));
const ComparePage = lazy(() => import('../pages/compare/ComparePage'));
const SettingsPage = lazy(() => import('../pages/settings/SettingsPage'));
const ContactedListPage = lazy(() => import('../pages/contacted-list/ContactedListPage'));
const ResetPasswordPage = lazy(() => import('../pages/reset-password/ResetPasswordPage'));
const PrivacyPolicyPage = lazy(() => import('../pages/privacy-policy/PrivacyPolicyPage'));
const TermsAndConditionsPage = lazy(() => import('../pages/terms-and-conditions/TermsAndConditionsPage'));
const AboutVuSpacePage = lazy(() => import('../pages/about-vu-space/AboutVuSpacePage'));
const LandingPage = lazy(() => import('../pages/landing/LandingPage'));



const Routes = () => {
	const user = useSelector((userState:ReduxState) => userState.user);
	const dispatch = useDispatch();
	const history = useHistory();

	//#region check changes to user data
	const fetchUser = async (token,id,name,email,phone,countryCode) => {
		dispatch(setUserAction({
			name,
			email,
			phone,
			countryCode,
			token,
			_id:id,
			favoriteDevelopersIds: await Api.users.getFavoriteDevelopers(token,id).then(data => data.favoriteDevelopers.map(dev=> dev._id)),
			favoriteProjectsIds: await Api.users.getFavoriteProjects(token,id).then(data => data.favoriteProjects.map(dev=> dev._id)),
			favoritePropertiesIds: await Api.users.getFavoriteProperties(token,id).then(data => data.favoriteProperties.map(dev=> dev._id)),
		}))
	}

	const checkUserInfo = (token,id) => {
		(async () => await Api.users.getOne(token,id).then(r=>{
			fetchUser(r.token,r._id,r.name,r.email,r.phone,r.countryCode);
		}).catch(e=> {
			if (e.response.status === 403 || e.response.status === 401) {
				localStorage.removeItem("USER_WEB_INFO");
				dispatch(logoutUserAction())
				history.push('/')
			}
		}))()
	}
	//#endregion

	//#region Check if user exists
	useEffect(()=>{
		if (user._id){
			checkUserInfo(user.token,user._id)
		}
	},[user._id])
	//#endregion
	return (
		<Suspense fallback={<LoadingDashBoard/>}>
			<Switch>
				<Route exact path='/madhood' component={MadhoodPage}/>
				<Route exact path='/maroziz' component={MarozizPage}/>
				<Route exact path='/shalaby' component={ShalabyPage}/>
				<Route exact path='/youssef' component={YoussefPage}/>
				<Route exact path='/project/:id/:matchedProperties?' component={ProjectPage}/>
				<Route exact path='/property/:id' component={PropertyPage}/>
				<Route exact path='/developer/:id' component={DeveloperPage}/>
				<Route exact path='/explore' component={ExplorePage}/>
				<Route exact path='/filter' component={FilterPage}/>
				<Route exact path='/' component={HomePage}/>
				<Route exact path='/register' component={RegisterPage}/>
				<Route exact path='/login' component={LoginPage}/>
				{user._id && <Route exact path='/saved' component={SavedPage}/>}
				<Route exact path='/compare/:comparedProperties' component={ComparePage}/>
				<Route exact path='/settings' component={ SettingsPage }/>
				<Route exact path='/contacted-list' component={ ContactedListPage }/>
				<Route exact path='/reset-password/:id/:token' component={ ResetPasswordPage }/>
				<Route exact path='/privacy-policy' component={ PrivacyPolicyPage }/>
				<Route exact path='/terms-and-conditions' component={ TermsAndConditionsPage }/>
				<Route exact path='/about-vu-space' component={ AboutVuSpacePage }/>
				<Route exact path='/landing/:type?/:id?' component={ LandingPage }/>
				<Redirect to={'/'} />
			</Switch>
		</Suspense>
	);
}

export default Routes;